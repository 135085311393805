import "@fontsource/oxygen";
import "@fontsource/quicksand";
import { appWithTranslation } from "next-i18next";
import "styles/global.css";
import Script from 'next/script'
import Head from "next/head";


function Rooph({ Component, pageProps }) {
  return (
    <>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PDLNGTS" height="0" width="0" style="display: none; visibility: hidden;" />`
        }}
        />


        <Script id="google-tag-manager" strategy="afterInteractive">
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-PDLNGTS');
          `}
        </Script>
      <Component {...pageProps} />
    </>
  )
}

export default appWithTranslation(Rooph);
